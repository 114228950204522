import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { datadetail,modifydetail,deleteword } from '../actions'
import { Link } from 'react-router-dom'
import { FormControl, FormGroup, FormLabel,Button,Badge } from 'react-bootstrap'
import { withAuthenticator} from "aws-amplify-react";


class ModifyDetil extends Component {
  componentDidMount(){ 
    const { id } = this.props.match.params
    if(id) this.props.datadetail({"id": id})
  } 
  constructor(props) { 
    super(props) 
    this.state = { register_result: "" } 
  } 
  renderTextField(field) {
    const { input, label } = field
    return (
          <FormGroup controlId="exampleFormControlInput1">
            <FormLabel>{label}</FormLabel>
            <FormControl {...input} type="text" placeholder={label} />
          </FormGroup>

    )
  }
  renderAreaField(field) {
    const { input, label } = field
    return (
          <FormGroup controlId="exampleFormControlTextarea1">
            <FormLabel>{label}</FormLabel>
            <FormControl {...input} as="textarea" rows="3" placeholder={label} />
          </FormGroup>
    )
  }
  
  async registerButton(id,english_word){
    let temp_json = {}
    temp_json["id"] = id
    temp_json["english_word"] = english_word
    await this.props.deleteword(temp_json)
    this.props.history.push('/Listdisplay/' + this.props.event.dictionary_name + "." + this.props.event.login_id)
  }
  async onSubmit(values) { 
    await this.props.modifydetail(values)
    this.setState({ register_result: "register_result :" +this.props.event.resut_value}) 
  }

  render() {
    const { handleSubmit } = this.props 
    return (
      <div>
        <form onSubmit={handleSubmit(this.onSubmit.bind(this) )}>
          EnglishWord
          <h3>{this.props.event.englishword}</h3>
          <Field label='JapaneseWord'     name='japaneseword'     component={this.renderTextField} />
          <Field label='EnglishExampleSentence' name='exampleensentence' component={this.renderAreaField} />
          <Field label='JapaneseExampleSentence' name='examplejasentence' component={this.renderAreaField} />
          <Field label='Description' name='description' component={this.renderAreaField} />
          <Button variant="primary" type="submit" style={{ marginRight: '20px',marginLeft: '10px' }}>
            Submit
          </Button>
          <Button variant="danger" style={{ marginRight: '20px',marginLeft: '10px' }} onClick={e => this.registerButton(this.props.event.id,this.props.event.englishword)}>Delete</Button>
          <Link to={`/Listdisplay/${this.props.event.dictionary_name}.${this.props.event.login_id}`} ><Badge pill variant="success">All List</Badge></Link>
        </form>
        <br />
        <br /><br />{ this.state.register_result }
      </div>
      )
  }
}

const mapStateToProps = (state) => {
  const event = {   
                    "englishword" : state.detailevent["english_word"]
                   ,"japaneseword" : state.detailevent["japanese_word"]
                   ,"exampleensentence" : state.detailevent["english_sentence"]
                   ,"examplejasentence" : state.detailevent["japanese_sentence"]
                   ,"description" : state.detailevent["event_description"]
                   ,"login_id" : state.detailevent["login_id"]
                   ,"dictionary_name" : state.detailevent["dictionary_name"]
                   ,"id": state.detailevent["id"]
                }
  if(state["modifywordevent"]){
    if(state.modifywordevent["value"])
      event["resut_value"] = state.modifywordevent.value
  }
  return { initialValues : event,event }
}

const mapDispatchToProps = ({ datadetail,modifydetail,deleteword})  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({ form: 'modifyDetilForm',enableReinitialize:true })(withAuthenticator(ModifyDetil))
)