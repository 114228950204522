import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { dictionaryinput } from '../actions'
import { FormControl, FormGroup, FormLabel,Button } from 'react-bootstrap'
import { withAuthenticator} from "aws-amplify-react";

class Createdictionary extends Component {
  constructor(props) { 
    super(props) 
    this.state = { register_result: "" } 
  } 
  renderTextField(field) {
    const { input, label } = field
    return (
        <FormGroup controlId="exampleFormControlInput1">
            <FormLabel>Dictionary Name</FormLabel>
            <FormControl {...input} type="text" placeholder={label} />
        </FormGroup>
    )
  }
  async onSubmit(values) { 
    console.log(values)
    await this.props.dictionaryinput(values)
    this.setState({ register_result: "register_result :" + this.props.result}) 
    this.props.reset()
  } 
  render() {
    const { handleSubmit } = this.props 
    return (
      <div>
        <form onSubmit={handleSubmit(this.onSubmit.bind(this) )}>
          <div><Field label='DictionaryName'  name='dictionaryname' component={this.renderTextField} /></div>
          <div>
          <Button variant="primary" type="submit">
            Submit
          </Button>
          </div>
        </form>
        { this.state.register_result }
      </div>
      )
  }
}
const mapStateToProps = state => ({ result: state.newdictevent.value})
const mapDispatchToProps = ({ dictionaryinput })
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({ form: 'createDictionaryForm'})(withAuthenticator(Createdictionary))
)
