import React, { Component } from 'react';
import { Navbar,Nav,Form,FormControl,Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'; 


class Header extends Component {
  logoutButton = () => {  
    this.setState({ user_name:"" }) 
    Auth.signOut({ global: true }) 
      .then(data => console.log(data)) 
      .catch(err => console.log(err)); 
  }  
  render() {
    return (
        <Navbar bg="dark" variant="dark">
            <Navbar.Brand><Link to="/" style={{ marginTop: '8px',color: '#E0B000',textDecoration: 'none' }}>
                <img
                    alt=""
                    src="https://d59fnyuaphnzn.cloudfront.net/download-3.jpg"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    style={{ marginRight: '5px' }}
                />
                MEMORY BREAD
            </Link></Navbar.Brand>
            <Nav className="mr-auto">
              <Nav.Link><Link to="/" style={{ marginLeft: '15px',color: '#ffffff',textDecoration: 'none' }}>Top</Link></ Nav.Link>
              <Nav.Link><Link to="/UserPage" style={{ marginLeft: '15px',color: '#ffffff',textDecoration: 'none' }}>UserPage</Link></ Nav.Link>
              <Nav.Link><Link to="/Createdictionary" style={{ marginLeft: '15px',color: '#ffffff',textDecoration: 'none' }}>CreateMyDictionary</Link></ Nav.Link>
              <Nav.Link><Link to="/Dictionarysearch" style={{ marginLeft: '15px',color: '#ffffff',textDecoration: 'none' }}>DictionarySearch</Link></ Nav.Link>
            </Nav>
            <Button variant="outline-danger" onClick={this.logoutButton} style={{ marginLeft: '15px' }}> 
              logout 
            </Button> 
        </Navbar>
    );
  }
}
export default Header;