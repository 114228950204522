export default function deletewordevent (event = {}, action) { 
    switch (action.type) {
    case "DELETE_WORD":
        return {
            ...event,
            value: action.response.data.body
        }
    default: 
        return event 
    }
}
