import { combineReducers } from 'redux' 
import { reducer as form } from 'redux-form'  
import putevent from './putevent'
import displayevent from './displayevent'
import detailevent from './detailevent'
import dictionaryevent from './dictionaryevent'
import newdictevent from './newdictevent'
import searchdictevent from './searchdictevent'
import modifywordevent from './modifywordevent'
import deletewordevent from './deletewordevent'
import ownerdictevent from './ownerdictevent'
 
export default combineReducers({ putevent,displayevent,detailevent,dictionaryevent,newdictevent,searchdictevent,modifywordevent,deletewordevent,ownerdictevent, form }) 