import _ from 'lodash' 

export default function dictionaryevent (result = {}, action) { 
    switch (action.type) {
    case "DICTIONARY_LIST":
　      return _.mapKeys(JSON.parse(action.response.data.body),'id')
    default: 
        return result 
    }
}
