import React from 'react'; 
import ReactDOM from 'react-dom'; 
import { createStore, applyMiddleware } from 'redux' 
import { Provider } from 'react-redux' 
import thunk from 'redux-thunk' 
import { BrowserRouter, Route , Switch } from 'react-router-dom' 
import Amplify from "aws-amplify";
 
import './index.css'; 
import reducer from './reducers' 
import Register from './components/Register';
import Listdisplay from './components/Listdisplay';
import Detaildisplay from './components/Detaildisplay';
import DictionaryList from './components/DictionaryList';
import Createdictionary from './components/Createdictionary';
import Dictionarysearch from './components/Dictionarysearch';
import ModifyDetil from './components/ModifyDetil';
import Header from './components/Header';
import UserPage from './components/UserPage';
import * as serviceWorker from './serviceWorker'; 

const store = createStore(reducer,applyMiddleware(thunk)) 

Amplify.configure({
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_1BwpxALYF',
    userPoolWebClientId: 'i3alpblofh350uimrr8lribc2',
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
        <Header />
        <Route exact path='/' component={DictionaryList} />
        <Route exact path='/UserPage' component={UserPage} />
        <Route exact path='/Createdictionary' component={Createdictionary} />
        <Route exact path='/Dictionarysearch' component={Dictionarysearch} />
        <Route path='/Detaildisplay/:id' component={Detaildisplay} />
        <Route path='/Register/:id' component={Register} />
        <Route path='/Listdisplay/:id' component={Listdisplay} />
        <Route path='/ModifyDetil/:id' component={ModifyDetil} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();

