import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { ownerdictsearch } from '../actions'
import _ from 'lodash' 
import { Link } from 'react-router-dom'
import { Image,Table,Badge } from 'react-bootstrap';
import { withAuthenticator} from "aws-amplify-react";
import { Auth } from 'aws-amplify'; 

class UserPage extends Component {
  constructor(props) { 
    super(props) 
    this.state = { username: "" } 
  }
  async componentDidMount() { 
    const temp = await Auth.currentAuthenticatedUser()
    await this.props.ownerdictsearch({ possess_user: temp.username})
    this.setState({ user_name:temp.username, header: "Dictionary",count: temp.username.length,body: this.props.result}) 
    console.log(this.props.result)
  }
  renderEvents(){
    return _.map(this.state.body,event =>
      <tr key={event.id}>
        <td><Link to={`/Listdisplay/${event.dictionary_name}.${event.login_id}`}><Badge variant="light">{event.dictionary_name}</Badge></Link></td>
      </tr>
    )
  }
  render() {
    return (
      <div>
          <h1 style={{ marginLeft: '15px',marginTop: '15px',marginBottom: '15px',color: '#ffffff',textDecoration: 'none' }}>User: {this.state.user_name}  (MyDictionary: {this.state.count})</h1>
          <Image src="https://d59fnyuaphnzn.cloudfront.net/download-3.jpg" rounded className="rounded float-left" style={{ marginLeft: '70px',marginBottom: '20px' }} />
          <Table striped bordered hover variant="dark"> 
            <thead> 
              <tr> 
                <th>{ this.state.header }</th> 
              </tr> 
            </thead> 
            <tbody> 
              {this.renderEvents()} 
            </tbody> 
          </Table> 
      </div>
      )
  }
}
const mapStateToProps = state => ({ result: state.ownerdictevent})
const mapDispatchToProps = ({ ownerdictsearch })
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({ form: 'createDictionaryForm'})(withAuthenticator(UserPage))
)
