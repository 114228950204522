import _ from 'lodash' 

export default function ownerdictevent (result = {}, action) { 
    switch (action.type) {
    case "OWNERDICT_SEARCH":
　      return _.mapKeys(JSON.parse(action.response.data.body),'id')
    default: 
        return result 
    }
}
