import _ from 'lodash' 

export default function searchdictevent (event = {}, action) { 
    switch (action.type) {
    case "DICTIONARY_SEARCH":
　      return _.mapKeys(JSON.parse(action.response.data.body),'id')
    default:
        return event;
    }
}
