import React, { Component } from 'react';
import { connect } from 'react-redux'
import { datadetail,datadetailprev,datadetailnext } from '../actions'
import { Link } from 'react-router-dom'
import { Accordion, Card, Button ,Badge } from 'react-bootstrap'
import { withAuthenticator} from "aws-amplify-react";

class Detaildisplay extends Component {
  componentDidMount(){ 
    const { id } = this.props.match.params
    if(id) this.props.datadetail({"id": id})
  } 
  prevButton = () => { 
    this.props.datadetailprev({"id": this.props.detailevent.id})
  } 
  nextButton = () => { 
    this.props.datadetailnext({"id": this.props.detailevent.id})
  } 
  render() {
    return (
      <div>
        <h3>english word</h3>
        <Accordion>
          <Card style={{ backgroundColor: '#2e3439' }}>
            <Accordion.Toggle as={Card.Header} eventKey="1">
                { this.props.detailevent.english_word }
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1" style={{ backgroundColor: '#3E444A' }}>
              <Card.Body>
                  { this.props.detailevent.japanese_word }<br />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br /><h3>english sentence</h3>
        <Accordion >
          <Card style={{ backgroundColor: '#2e3439' }}>
            <Accordion.Toggle as={Card.Header} eventKey="0">
                { this.props.detailevent.english_sentence }<br /><br />
                <audio src={`https://d59fnyuaphnzn.cloudfront.net/${ this.props.detailevent.en_s3_key }`} controls/>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0" style={{ backgroundColor: '#3E444A' }}>
              <Card.Body>
                  { this.props.detailevent.japanese_sentence }<br /><br />
                  <audio src={`https://d59fnyuaphnzn.cloudfront.net/${ this.props.detailevent.jp_s3_key }`} controls/>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <h3>description</h3>
        <Accordion>
          <Card style={{ backgroundColor: '#2e3439' }}>
            <Accordion.Toggle as={Card.Header} eventKey="1">
                { this.props.detailevent.event_description }
            </Accordion.Toggle>
          </Card>
        </Accordion>

        <Button variant="primary" onClick={this.prevButton} style={{ marginRight: '20px',marginLeft: '10px',marginTop: '5px' }}>
            prev
        </Button>
        <Button variant="primary" onClick={this.nextButton} style={{ marginTop: '5px',marginRight: '20px' }}>
            next
        </Button>
        <Link to={`/Listdisplay/${this.props.detailevent.dictionary_name}.${this.props.detailevent.login_id}`} ><Badge pill variant="success">All List</Badge></Link>
      </div>
      )
  }
}
const mapStateToProps = state => ({ detailevent: state.detailevent}) 
const mapDispatchToProps = ({ datadetail,datadetailprev,datadetailnext })  
export default connect(mapStateToProps, mapDispatchToProps)(withAuthenticator(Detaildisplay))
