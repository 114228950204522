import axios from 'axios'
import Amplify, { Auth } from 'aws-amplify';
export const DATA_REGISTER = 'DATA_REGISTER'
export const OUTPUT_DATA   = 'OUTPUT_DATA'
export const DATA_DETAIL   = 'DATA_DETAIL'
export const DICTIONARY_LIST = 'DICTIONARY_LIST'
export const DICTIONARY_REGISTER   = 'DICTIONARY_REGISTER'
export const DICTIONARY_SEARCH   = 'DICTIONARY_SEARCH'
export const REGIST_OTHER   = 'REGIST_OTHER'
export const MODIFY_WORD   = 'MODIFY_WORD'
export const DELETE_WORD   = 'DELETE_WORD'
export const OWNERDICT_SEARCH   = 'OWNERDICT_SEARCH'
export const ELSE   = 'ELSE'
const user_name   = 'ttanaka'

Amplify.configure({
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_1BwpxALYF',
    userPoolWebClientId: 'i3alpblofh350uimrr8lribc2',
  },
});

export const datainput = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  values["userid"] = user.username
  const response = await axios.post("https://7ya4tqerq8.execute-api.ap-northeast-1.amazonaws.com/v1/inputword", values,myInit) 
  if (response.data.body){
      dispatch({ type: DATA_REGISTER,response }) 
  }else{
      dispatch({ type: ELSE}) 
  }
}

export const dataoutput = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  const response = await axios.post("https://7ya4tqerq8.execute-api.ap-northeast-1.amazonaws.com/v1/outputlist",values,myInit)
  if (response.data.body){
      dispatch({ type: OUTPUT_DATA ,response}) 
  }else{
      dispatch({ type: ELSE}) 
  }
}

export const datadetail = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  const response = await axios.post("https://7ya4tqerq8.execute-api.ap-northeast-1.amazonaws.com/v1/detail",values,myInit) 
  if (response.data.body){
      dispatch({ type: DATA_DETAIL ,response}) 
  }else{
      dispatch({ type: ELSE}) 
  }
}

export const datadetailprev = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  const response = await axios.post("https://7ya4tqerq8.execute-api.ap-northeast-1.amazonaws.com/v1/prev",values,myInit) 
  if (response.data.body){
      dispatch({ type: DATA_DETAIL ,response}) 
  }else{
      dispatch({ type: ELSE}) 
  }
}

export const datadetailnext = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  const response = await axios.post("https://7ya4tqerq8.execute-api.ap-northeast-1.amazonaws.com/v1/next",values,myInit) 
  if (response.data.body){
      dispatch({ type: DATA_DETAIL ,response}) 
  }else{
      dispatch({ type: ELSE}) 
  }
}

export const dictionarylistoutput = () => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  const values = {possess_user:user.username}
  const response = await axios.post("https://7ya4tqerq8.execute-api.ap-northeast-1.amazonaws.com/v1/dictionarylist",values,myInit) 
  if (response.data.body){
      dispatch({ type: DICTIONARY_LIST ,response}) 
  }else{
      dispatch({ type: ELSE}) 
  }
}

export const dictionaryinput = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  values["possess_user"]=user.username
  const response = await axios.post("https://7ya4tqerq8.execute-api.ap-northeast-1.amazonaws.com/v1/inputdictionary",values,myInit) 
  dispatch({ type: DICTIONARY_REGISTER,response}) 
}

export const dictionarysearch = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  values["possess_user"]=user.username
  const response = await axios.post("https://7ya4tqerq8.execute-api.ap-northeast-1.amazonaws.com/v1/searchdictionary",values,myInit) 
  if (response.data.body){
      dispatch({ type: DICTIONARY_SEARCH,response}) 
  }else{
      dispatch({ type: ELSE}) 
  }
}

export const registdictother = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  values["possess_user"]=user.username
  const response = await axios.post("https://7ya4tqerq8.execute-api.ap-northeast-1.amazonaws.com/v1/registotherdict",values,myInit) 
  if (response.data.body){
      dispatch({ type: REGIST_OTHER ,response}) 
  }else{
      dispatch({ type: ELSE}) 
  }
}

export const modifydetail = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  values["possess_user"]=user.username
  const response = await axios.post("https://7ya4tqerq8.execute-api.ap-northeast-1.amazonaws.com/v1/modifyword",values,myInit) 
  if (response.data.body){
      dispatch({ type: MODIFY_WORD ,response}) 
  }else{
      dispatch({ type: ELSE}) 
  }
}

export const deleteword = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  values["possess_user"]=user.username
  const response = await axios.post("https://7ya4tqerq8.execute-api.ap-northeast-1.amazonaws.com/v1/deleteword",values,myInit) 
  if (response.data.body){
      dispatch({ type: DELETE_WORD ,response}) 
  }else{
      dispatch({ type: ELSE}) 
  }
}

export const ownerdictsearch = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  const response = await axios.post("https://7ya4tqerq8.execute-api.ap-northeast-1.amazonaws.com/v1/ownerdictionary",values,myInit) 
  if (response.data.body){
      dispatch({ type: OWNERDICT_SEARCH,response}) 
  }else{
      dispatch({ type: ELSE}) 
  }
}