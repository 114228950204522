import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { datainput } from '../actions'
import { Link } from 'react-router-dom'
import { FormControl, FormGroup, FormLabel,Button,Badge } from 'react-bootstrap'
import { withAuthenticator} from "aws-amplify-react";
import { Auth } from 'aws-amplify'; 
const user_name = "ttanaka"


class Register extends Component {
  async componentDidMount(){
    const temp = await Auth.currentAuthenticatedUser()
    if(temp.username !== this.props.match.params.id.split('.')[1]){
        this.props.history.push('/Listdisplay/' + this.props.match.params.id)
    }
  } 
  constructor(props) { 
    super(props) 
    this.state = { register_result: "" } 
  } 
  renderTextField(field) {
    const { input, label } = field
    return (
          <FormGroup controlId="exampleFormControlInput1">
            <FormLabel>English Word(Required)</FormLabel>
            <FormControl {...input} type="text" placeholder={label} />
          </FormGroup>

    )
  }
  renderAreaField(field) {
    const { input, label } = field
    return (
          <FormGroup controlId="exampleFormControlTextarea1">
            <FormLabel>Example Textarea(Required)</FormLabel>
            <FormControl {...input} as="textarea" rows="3" placeholder={label} />
          </FormGroup>
    )
  }
  descriptionAreaField(field) {
    const { input, label } = field
    return (
          <FormGroup controlId="exampleFormControlTextarea2">
            <FormLabel>Description Area(Option)</FormLabel>
            <FormControl {...input} as="textarea" rows="3" placeholder={label} />
          </FormGroup>
    )
  }
  async onSubmit(values) { 
    values["dictionaryname"]=this.props.match.params.id.split('.')[0]
    await this.props.datainput(values)
    this.setState({ register_result: "register_result :" +this.props.result}) 
    this.props.reset()
    //this.props.history.push('/')
  } 
  render() {
    const { handleSubmit } = this.props 
    return (
      <div>
        <form onSubmit={handleSubmit(this.onSubmit.bind(this) )}>
          <Field label='EnglishWord'     name='englishword'     component={this.renderTextField} />
          <Field label='ExampleSentence' name='examplesentence' component={this.renderAreaField} />
          <Field label='Description' name='description' component={this.descriptionAreaField} />
          <Button variant="primary" type="submit" style={{ marginRight: '20px',marginLeft: '10px' }}>
            Submit
          </Button>
          <Link to={`/Listdisplay/${this.props.match.params.id}`} ><Badge pill variant="success">All List</Badge></Link>
        </form>
        <br />
        <br /><br />{ this.state.register_result }
      </div>
      )
  }
}
const mapStateToProps = state => ({ result: state.putevent.value}) 
const mapDispatchToProps = ({ datainput })  
export default connect(mapStateToProps, mapDispatchToProps)(
//  reduxForm({ form: 'loginPageForm',initialValues: { englishword: '' }})(Register)
  reduxForm({ form: 'loginPageForm'})(withAuthenticator(Register))
)