import _ from 'lodash' 

export default function displayevent (event = {}, action) { 
    switch (action.type) {
    case "OUTPUT_DATA":
　      return _.mapKeys(JSON.parse(action.response.data.body),'id')
    default:
        return event;
    }
}
