import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { dictionarysearch,registdictother } from '../actions'
import _ from 'lodash' 
import { Link } from 'react-router-dom'
import { FormControl, FormGroup, FormLabel,Table,Badge,Button } from 'react-bootstrap';
import { withAuthenticator} from "aws-amplify-react";

class Dictionarysearch extends Component {
  constructor(props) { 
    super(props) 
    this.state = { header1: "" } 
  }
  
  renderTextField(field) {
    const { input, label } = field
    return (
      <FormGroup controlId="exampleFormControlInput1">
        <FormLabel>Search Key Word</FormLabel>
        <FormControl {...input} type="text" placeholder={label} />
      </FormGroup>
    )
  }
  
  registerButton = (id) => { 
      this.props.registdictother({"id":id})
      this.setState(states => {
        for (var key in states["body"]) {
            if (states["body"][key]["id"] === id) states["body"][key]["exist_flg"] = 1
        }
        return { header1: "Dictionary",header2: "CreateUser",body: states["body"]}
    });
  }
  
  renderButtonEvent(values){
    if (values["exist_flg"] > 0){ return (<div>REGISTERED</div>) }
    else { return <Button variant="outline-light" onClick={e => this.registerButton(values["id"])}>REGISTER</Button>}
  }
  
  renderEvents(){
    return _.map(this.state.body,event =>
      <tr key={event.id}>
        <td><Link to={`/Listdisplay/${event.dictionary_name}.${event.login_id}`}><Badge variant="light">{event.dictionary_name}</Badge></Link></td>
        <td>{event.login_id}</td>
        <td>{this.renderButtonEvent(event)}</td>
      </tr>
    )
  }
  renderTable(){
    if ( this.state.header1 !== "" ){ 
      return (
          <Table striped bordered hover variant="dark"> 
            <thead> 
              <tr> 
                <th>{ this.state.header1 }</th> 
                <th>{ this.state.header2 }</th> 
                <th></th> 
              </tr> 
            </thead> 
            <tbody> 
              {this.renderEvents()} 
            </tbody> 
          </Table> 
      )
    }else{
      return <div />
    }
  }
  
  async onSubmit(values) { 
    await this.props.dictionarysearch(values)
    //this.setState({ register_result: this.props.result}) 
    this.setState({ header1: "Dictionary",header2: "CreateUser",body: this.props.result}) 
    this.props.reset()
  }
  
  render() {
    const { handleSubmit } = this.props 
    return (
      <div>
        <form onSubmit={handleSubmit(this.onSubmit.bind(this) )} style={{ marginBottom: '20px' }}>
          <Field label='Search'  name='searchkeyword' component={this.renderTextField} />
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </form>
          {this.renderTable()}
      </div>
      )
  }
}
const mapStateToProps = state => ({ result: state.searchdictevent})
const mapDispatchToProps = ({ dictionarysearch,registdictother })
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({ form: 'createDictionaryForm'})(withAuthenticator(Dictionarysearch))
)
