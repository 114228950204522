import React, { Component } from 'react';
import { connect } from 'react-redux'
import _ from 'lodash' 
import { Link } from 'react-router-dom'
import { dictionarylistoutput } from '../actions'
import { Col,Image,Row,Badge } from 'react-bootstrap';
import { withAuthenticator} from "aws-amplify-react";

class DictionaryList extends Component {
  componentDidMount(){ 
    this.props.dictionarylistoutput()
  } 
  renderEvents(){
    return _.map(this.props.dictionaryevent,event =>
      <Col lg={4} md={6} key={event.id}>
          <div style={{ position: 'relative' }}>
              <Image src="https://d59fnyuaphnzn.cloudfront.net/download-3.jpg" roundedCircle className="rounded mx-auto d-block" />
              <div style={{ position: 'absolute' ,top: '80%',left:'50%'}}>
                  <Link to={`/Register/${event.dictionary_name}.${event.login_id}`} className="rounded mx-auto d-block" >
                      <Badge pill variant="primary">{event.dictionary_name}</Badge>
                      <br />
                      <Badge pill variant="success">{event.login_id}</Badge>
                  </Link>
              </div>
          </div>
      </Col>
    )
  }
  render() {
    return (
      <div>
        <Row>
            {this.renderEvents()} 
        </Row>
      </div>
      )
  }
}
const mapStateToProps = state => ({ dictionaryevent: state.dictionaryevent}) 
const mapDispatchToProps = ({ dictionarylistoutput })  
export default connect(mapStateToProps, mapDispatchToProps)(withAuthenticator(DictionaryList))
