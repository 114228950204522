import React, { Component } from 'react';
import { connect } from 'react-redux'
import _ from 'lodash' 
import { Link } from 'react-router-dom'
import { dataoutput } from '../actions'
import { Table,Button,Badge } from 'react-bootstrap';
import { withAuthenticator} from "aws-amplify-react";
import { Auth } from 'aws-amplify'; 
const user_name = "ttanaka"

class Listdisplay extends Component {
  constructor(props) { 
    super(props) 
    this.state = { username: "" } 
  }
  async componentDidMount(){ 
    const temp = await Auth.currentAuthenticatedUser()
    let temp_json = {}
    temp_json["dictionary_name"] = this.props.match.params.id.split('.')[0]
    temp_json["owner_id"] = this.props.match.params.id.split('.')[1]
    this.props.dataoutput(temp_json)
    this.setState({user_name:temp.username}) 
  } 
  renderEvents(){
    if(this.state.user_name !== this.props.match.params.id.split('.')[1]){
        return _.map(this.props.displayevent,event =>
          <tr key={event.id}>
            <td><Link to={`/Detaildisplay/${event.id}`}><Button variant="light">{event.english_word}</Button></Link></td>
            <td>{event.english_sentence}</td>
          </tr>
        )
    }else{
        return _.map(this.props.displayevent,event =>
          <tr key={event.id}>
            <td><Link to={`/Detaildisplay/${event.id}`}><Button variant="light">{event.english_word}</Button></Link></td>
            <td>{event.english_sentence}</td>
            <td><Link to={`/ModifyDetil/${event.id}`}><Button variant="outline-light">MODIFY</Button></Link></td>
          </tr>
        )
    }
  }
  render() {
    return (
      <div>
        <Table striped bordered hover variant="dark"> 
          <thead> 
            <tr> 
              <th>English Word</th> 
              <th>English Sentence</th> 
            </tr> 
          </thead> 
          <tbody> 
            {this.renderEvents()} 
          </tbody> 
        </Table> 
        　<Link to={`/Register/${this.props.match.params.id}`} ><Badge pill variant="success">Register</Badge></Link>
      </div>
      )
  }
}
const mapStateToProps = state => ({ displayevent: state.displayevent}) 
const mapDispatchToProps = ({ dataoutput })  
export default connect(mapStateToProps, mapDispatchToProps)(withAuthenticator(Listdisplay))
