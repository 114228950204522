export default function putevent (state = {}, action) { 
    switch (action.type) {
    case "DATA_REGISTER":
        return {
            ...state,
            value: action.response.data.body
        }
    default:
        return state;
    }
}