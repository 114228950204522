export default function newdictevent (result = {}, action) { 
    switch (action.type) {
    case "DICTIONARY_REGISTER":
        return {
            ...result,
            value: action.response.data.body
        }
    default: 
        return result 
    }
}
